import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// Material-UI imports
import { Box } from "@mui/material";
// Custom imports
import { userIsAuthenticated } from "../helpers/common/userIsAuthenticated";
import { logOut } from "../helpers/common/logOut";
import fetchApi from "../helpers/common/fetchApi";

import { SessionModal, SessionTimer, Navigation } from "@globalholdings/ui-common-components";


const Layout = props => {
  //const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState(
    sessionStorage.getItem("firstName")
  );
  const [lastName, setLastName] = useState(sessionStorage.getItem("lastName"));
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  const handleOpenSessionModal = () => {
    setSessionModalOpen(true);
  };

  const handleCloseSessionModal = () => {
    setSessionModalOpen(false);
  };

  useEffect(() => {
    if (userIsAuthenticated()) {
      const fetchUserName = () => {
        console.log("fetching user");
        const api = `
        ${window.CONFIG.COMMON_API_URL}/users/user`;
        fetchApi("GET", api).then(response => {
          if (response.status === 200) {
            sessionStorage.setItem("firstName", response.data.firstName);
            sessionStorage.setItem("lastName", response.data.lastName);
            setFirstName(sessionStorage.firstName);
            setLastName(sessionStorage.lastName);
            setLoading(false);
          } else {
            setFirstName("");
            setLastName("");
          }
        });
      };
      if (
        !sessionStorage.getItem("firstName") &&
        !sessionStorage.getItem("lastName")
      ) {
        fetchUserName();
      } else {
        setLoading(false);
      }
    }
  }, [firstName, lastName]);

  if (!loading && userIsAuthenticated() && window.location.pathname !== "/") {
    return (
      <>
        <Navigation
          brandMarkLink="/exceptions"
          appName="CommunicationPreferences"
          firstName={firstName}
          lastName={lastName}
          logOutPath={`${window.CONFIG.AUTH_UI_URL}/?referrer=UI.CommunicationPreferences`}
          counts="1"
          links={[
            {
              order: 0,
              title: "Home",
              href: "/home"
            }
          ]}
        />
        <SessionTimer
          sessionTime={20}
          sessionCallback={handleOpenSessionModal}
        />
        <SessionModal
          open={sessionModalOpen}
          counter={120}
          timeOutFunction={() => {
            logOut(`${window.CONFIG.AUTH_UI_URL}/?referrer=UI.CommunicationPreferences`);
          }}
          handleModalAction={handleCloseSessionModal}
        />
        <Box sx={{ margin: "12px 16px" }}>{props.children}</Box>
      </>
    );
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
};

export default withRouter(Layout);
