export function userIsAuthenticated() {
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get("accesstoken");
  const username = urlParams.get("username");
  const refreshToken = urlParams.get("refreshtoken");
  if (accessToken && refreshToken && username) {
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("refreshToken", refreshToken);
    return true;
  }
  if (
    sessionStorage.getItem("accessToken") &&
    sessionStorage.getItem("userName") &&
    sessionStorage.getItem("refreshToken")
  ) {
    return true;
  }
  return false;
}
