import React from "react";
import { Route } from "react-router-dom";
// Custom imports
import { userIsAuthenticated } from "../../helpers/common/userIsAuthenticated";
import { Loading } from "@globalholdings/ui-common-components";
import LoginRedirect from "./loginRedirect";

export default function PrivateRoute(props) {
  const { component: Component, temporary: Temporary, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        userIsAuthenticated() ? (
          <Component {...props} />
        ) : (
          <LoginRedirect
            authPath={"/exceptions"}
            loadingComponent={() => <Loading />}
          />
        )
      }
    />
  );
}
